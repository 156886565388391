<template>
  <div class="tel_visits">
    <p class="tip">重要：急重症不适合网上咨询，请立即前往当地医院就诊！</p>
    <div class="header">
      <div class="doctor_info" v-if="docType == 2">
        <img :src="docDetail.image" alt="">
        <div class="right">
          <p>
            <span>{{docDetail.name}}</span>
            <span>{{docDetail.clinic_name}}</span>
            <span>{{docDetail.title}}</span>
          </p>
          <p>{{docDetail.hospital}}</p>
        </div>
      </div>
      <div class="doctor_info" v-if="docType == 1">
        <img :src="devDoctorDetail.doctorHeadImg" alt="">
        <div class="right">
          <p>
            <span>{{devDoctorDetail.doctorName}}</span>
            <span>{{devDoctorDetail.doctorClinic}}</span>
            <span>{{devDoctorDetail.doctorProfessionalTitle}}</span>
          </p>
          <p>{{devDoctorDetail.doctorHospitalName}}</p>
        </div>
      </div>
      <div class="tel_type">
        <div class="title">
          <p></p>
          <p>选择方式</p>
        </div>
        <div class="btn">
          <div>直接电话</div>
        </div>
      </div>
    </div>
    <div class="times">
      <div class="title">
        <p></p>
        <p>电话时长</p>
      </div>
      <div class="choose" v-if="docType == 2">
        <div class="item" v-for="(item, index) in priceList" @click="handleTelTime(item,index)" :class="[indx == index ? 'handleTel' : '']" :key="index">￥{{item.money}}/次({{item.time}}分钟)</div>
      </div>
      <div class="choose" v-if="docType == 1">
        <div class="item" v-for="(item, index) in devTelPrice" @click="handleTelTime(item,index)" :class="[indx == index ? 'handleTel' : '']" :key="index">￥{{item.consultPrice}}/次({{getTime(item.duration)}}分钟)</div>
      </div>
    </div>
    <div class="phone">
      <van-field v-model="phone" label="接听电话" placeholder="请输入问诊电话号码（必填）" />
    </div>
    <div class="remark">
      <div>客户须知</div>
      <div>1.服务流程 <span>支付成功后5分钟内，平台会给您来电，号码为010区号的固定电话与您联系</span></div>
      <div>2.请您确保填写的接听电话号码可以正常通话</div>
      <div>3.为保证咨询通话效率，建议事先准备好病情材料</div>
      <div>4.如通话失败，本次咨询不收取费用。如通话过程中意外挂断，可在规定时间内继续拨打</div>
      <div>5.同一个号码，同一时间段只预约一次</div>
    </div>
    <div class="btns">
      <button type="button" name="button" @click="submit">提交</button>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      phone: '',
      indx: 0,
      docDetail: {},
      priceList: [],
      devDoctorDetail: {},
      devTelPrice: [],
      docType: 2,
      doctorTime: '',
      item: ''
    }
  },
  created() {
    this.docType = this.$route.query.docType;
    this.$store.state.docType = this.docType;
    if (this.$route.query.docType == 2) {
      this.doctorDetail(this.$route.query.doctor_id);
      this.getDocterTime(this.$route.query.doctor_id);
    } else {
      this.devDocDetail(this.$route.query.doctor_id);
      this.devDocPrice(this.$route.query.doctor_id);
    }
  },
  beforeRouteEnter(to, from, next) {
    // console.log('进来之前', to, from)
    if (from.path === '/phone_order') {
      to.meta.keepAlive = true;
    } else {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    // 时间
    getTime(time) {
      return {
        1: '10',
        2: '15',
        3: '20',
        4: '25',
        5: '30'
      }[time]
    },
    // 药康夫医生价格
    devDocPrice(id) {
      let params = {
        doctorId: id
      }
      this.$http('get', '/server/doctorPriceConf/list', params, 1).then(res => {
        // console.log('价格', res);
        if (res.data.code == 200) {
          let arr = res.data.data;
          let devTelPrice = [];
          if (arr.length) {
            arr.forEach((item, index) => {
              if (item.consultType == 2) {
                if (item.consultPrice > 0) {
                  item.consultPrice = (item.consultPrice * (1 + this.$store.state.floatRate)).toFixed(2);
                  devTelPrice.push(item);
                }
              }
            })
            this.devTelPrice = devTelPrice;
            this.item = this.devTelPrice[0];
          }
        }
      })
    },
    // 药康夫医生详情
    devDocDetail(id) {
      let params = {
        doctorId: id
      }
      this.$http('get', '/server/doctor/detail', params, 1).then(res => {
        // console.log(res);
        if (res.data.code == 200) {
          this.devDoctorDetail = res.data.data;
        }
      })
    },
    handleTelTime(item, index) {
      this.indx = index;
      this.item = item;
    },
    // 春雨医生价格
    getDocterTime(id) {
      let params = {
        doctor_id: id
      }
      this.$http('post', '/cooperation/server/phone/get_doctor_phone_info/', params).then(res => {
        if (res.data.error == 0) {
          this.doctorTime = res.data;
        }
      })
    },
    // 医生详情
    doctorDetail(id) {
      let params = {
        doctor_id: id
      }
      this.$http('post', '/cooperation/server/doctor/detail', params).then(res => {
        // console.log('详情', res);
        this.docDetail = res.data;
        this.docDetail.price = (this.docDetail.price / 100).toFixed(2);
        this.docDetail.tel_price = JSON.parse(this.docDetail.tel_price);
        let tel_price = this.docDetail.tel_price;
        // console.log(tel_price)
        let arr = [];
        for (let i in tel_price) {
          let item = tel_price[i];
          if (item > 0) {
            arr.push({ time: i, money: ((item / 100) * (1 + this.$store.state.floatRate)).toFixed(2) })
          }
        }
        this.priceList = arr;
        this.item = this.priceList[0];
      })
    },
    submit() {
      // console.log('sdfsdfsf', this.item);
      let priceInfo = {
        tel_no: this.phone,
        inquiry_time: this.doctorTime.recent_subscribable_time
      };
      if (this.docType == 2) {
        priceInfo.minutes = this.item.time,
          priceInfo.price = this.item.money
      } else {
        priceInfo.minutes = this.getTime(this.item.duration),
          priceInfo.price = this.item.consultPrice
      }
      this.$store.state.priceInfo = priceInfo;
      // console.log('问诊信息', this.$store.state.priceInfo)
      const flag = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!flag.test(this.phone)) {
        Toast('请输入正确的手机号');
        return;
      }
      if (this.$store.state.message) {
        this.$router.push({ path: '/phone_order', query: { phone: this.phone } });
      } else {
        this.$store.state.visitsType = 2
        this.$router.push({ path: '/choose_patients' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tel_visits {
  min-height: 100%;
  background: #f8f8f8;
  .tip {
    color: #666666;
    font-size: 0.24rem;
    text-align: center;
    line-height: 0.8rem;
  }
  .header {
    background: #fff;
    padding: 0.4rem 0.28rem;
    width: calc(100% - 0.56rem);
    .doctor_info {
      display: flex;
      align-items: center;
      padding-bottom: 0.22rem;
      border-bottom: 1px solid #f2f2f2;
      img {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.12rem;
        border-radius: 50%;
      }
      .right {
        p:nth-child(1) {
          margin-bottom: 0.2rem;
          span:nth-child(1) {
            font-size: 0.36rem;
            color: #222;
            font-weight: 700;
          }
          span:nth-child(2),
          span:nth-child(3) {
            font-size: 0.24rem;
            color: #666666;
            margin-left: 0.16rem;
          }
        }
        p:nth-child(2) {
          font-size: 0.28rem;
          color: #333;
          font-weight: 400;
        }
      }
    }
    .tel_type {
      margin-top: 0.26rem;
      .title {
        display: flex;
        align-items: center;
        p:nth-child(1) {
          width: 2px;
          height: 16px;
          border-radius: 2px;
          background: #42cec8;
        }
        p:nth-child(2) {
          font-size: 0.28rem;
          color: #333;
          margin-left: 0.1rem;
        }
      }
      .btn {
        margin: 0.22rem 1.14rem 0;
        width: calc(100% - 2.28rem);
        div {
          width: 2.4rem;
          height: 0.7rem;
          line-height: 0.7rem;
          background: #42cec8;
          text-align: center;
          color: #fff;
          font-size: 0.3rem;
          border-radius: 4px;
        }
      }
    }
  }
  .times {
    background: #fff;
    padding: 0.4rem 0.28rem;
    width: calc(100% - 0.56rem);
    margin: 0.1rem 0;
    .title {
      display: flex;
      align-items: center;
      p:nth-child(1) {
        width: 2px;
        height: 16px;
        border-radius: 2px;
        background: #42cec8;
      }
      p:nth-child(2) {
        font-size: 0.28rem;
        color: #333;
        margin-left: 0.1rem;
      }
    }
    .choose {
      margin: 0.24rem 0.6rem;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 2.6rem;
        height: 0.72rem;
        line-height: 0.72rem;
        text-align: center;
        font-size: 0.28rem;
        color: #666666;
        border-radius: 4px;
        border: 1px solid #42cec8;
        margin-right: 0.22rem;
        margin-bottom: 0.2rem;
      }
      .handleTel {
        background: #42cec8;
        color: #fff;
      }
    }
  }
  .phone {
    /deep/ .van-field__label {
      font-size: 0.28rem;
      color: #333;
      margin-right: 0;
    }
  }
  .remark {
    padding: 0.28rem 0.42rem;
    width: calc(100% - 0.84rem);
    div {
      color: #666666;
      font-size: 0.26rem;
      line-height: 0.42rem;
    }
    div:nth-child(1) {
      color: #333333;
      font-size: 0.28rem;
      margin-bottom: 0.12rem;
    }
    div:nth-child(2) {
      span {
        color: #bd3d30;
      }
    }
  }
  .btns {
    padding: 0 0.52rem 0.4rem;
    // margin: 0.4rem 0;
    margin-top: 0.4rem;
    width: calc(100% - 1.04rem);
    button {
      border: 0;
      width: 100%;
      height: 0.72rem;
      line-height: 0.72rem;
      background: #42cec8;
      color: #ffffff;
      border-radius: 0.08rem;
      font-size: 0.32rem;
    }
  }
}
</style>

